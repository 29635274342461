import React from 'react';
import './App.css';
import { Header, Eyebrow, P, Social } from './components/atoms'

const App = () => {
  return (
    <div className="App">
      <div id="container">
        <div>
          <Header>Gan Bei Gals</Header>
          <p style={{marginTop: -8, fontSize: 12}}>Celebrating Asian-American shared nostalgia and experiences through the art of food and design.</p>
        </div>
        <div>
          <Eyebrow>Past Events</Eyebrow>
          <P link='https://www.instagram.com/p/CD1vljOFhd2/'>DaijoubuMart Pop-Up</P>
          <P link='https://www.instagram.com/p/CAyXas3FWgL/'>Wing Fundraiser for Reclaim The Block</P>
          <P link='https://www.tinyurl.com/gbglny/'>Lunar New Year Hot Pot Dinner</P>
        </div>
        <div>
          <Eyebrow>Press</Eyebrow>
          <P link='https://austin.eater.com/2020/8/13/21363504/daijoubumart-asian-market-east-austin-cocktails-onigiri'>Eater Austin</P>
          <P link='https://tribeza.com/gan-bei-gals-austin-asian-food/'>Tribeza</P>
          <P>Spark Magazine</P>
          <P>Silk Club</P>
          <P>Con Acento Podcast</P>
        </div>
        <div style={{ display: 'inline-block'}}>
          <Social link="https://www.instagram.com/ganbeigals/">@ganbeigals</Social> / <Social>ganbeigals@gmail.com</Social>
        </div>
      </div>
    </div>
  );
}

export default App;